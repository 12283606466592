import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AppContext from "./components/AppContext";
import { Amplify } from "aws-amplify";
import config from "./amplifyconfiguration.json";
import Home from "./components/Home";
import Payment from "./components/Payment";
import "./App.css";

Amplify.configure(config);

function App() {
  const [testVariable, setTestVariable] = useState("test");

  return (
    <AppContext.Provider
      value={{
        testVariable,
        setTestVariable,
      }}
    >
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/payment" element={<Payment />} />
        </Routes>
      </Router>
    </AppContext.Provider>
  );
}

export default App;
