import React from "react";
import {
  CreditCard,
  Divider,
  PaymentForm,
} from "react-square-web-payments-sdk";

// Please see: https://react-square-payments.weareseeed.com/docs
// Test Data: https://developer.squareup.com/docs/devtools/sandbox/payments

function SquarePaymentForm({
  accessToken,
  amount,
  billingInformation,
  applicationId,
  locationId,
  createSquarePayment,
  isLive,
}) {
  return (
    <PaymentForm
      applicationId={applicationId}
      locationId={locationId}
      cardTokenizeResponseReceived={async (
        paymentDetails
      ) => {

        if (paymentDetails.status !== "OK") {
          alert(
            "Payment verification failed. Please try another payment method."
          );
          return;
        }

        const requestBody = {
          accessToken: accessToken,
          paymentDetails: paymentDetails,
          amount: amount,
          billingContact: billingInformation,
        };

        createSquarePayment(requestBody)
          .then((data) => {
            console.log("Payment response:", data);
          })
          .catch((error) => {
            alert(error.message || "Failed to create Square payment");
          });
      }}
    >
      <CreditCard>Pay ${amount / 100}</CreditCard>
      <Divider />
    </PaymentForm>
  );
}

export default SquarePaymentForm;
