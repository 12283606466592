const apiURL = `https://api.bitsmarketwallet.com/${
  process.env.REACT_APP_AMPLIFY_ENV || "dev"
}/`;

// Function for getting Square application ID and location ID
export const getSquareInfo = async () => {
  const squareInfoEndpoint = "square/info";
  const request = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  try {
    const response = await fetch(apiURL + squareInfoEndpoint, request);
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

// Function for creating a Square payment
export const createSquarePayment = async (paymentData) => {
  const createPaymentEndpoint = "square/pay";
  const request = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(paymentData),
  };

  try {
    const response = await fetch(apiURL + createPaymentEndpoint, request);
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

// Helper function to handle the response
const handleResponse = async (response) => {
  const data = await response.json();
  if (response.ok) {
    return data;
  } else {
    throw new Error(data.message || "An error occurred");
  }
};

// Helper function to handle errors
const handleError = (error) => {
  console.error("API call failed: ", error);
  throw error;
};
