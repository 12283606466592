import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import AppContext from "./AppContext";
import "./style/Home.css";

function Home() {
  const { testVariable, setTestVariable } = useContext(AppContext);

  return (
    <div className="home-container">
      <Helmet>
        <title>Bits Market Wallet</title>
      </Helmet>

      {/* Header */}
      <h1>Welcome To Bits Market Wallet!</h1>

      {/* Payment */}
      <div className="payment-container">
        <h2>Payment</h2>
        <a href="/payment">Go to payment page</a>
      </div>
    </div>
  );
}

export default Home;
