import React, { useEffect, useState, useContext } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import SquarePaymentForm from "./SquarePaymentForm";
import { getSquareInfo, createSquarePayment } from "./APIService";
import "./style/Payment.css";

function Payment() {
  const [hasAuthError, setHasAuthError] = useState(false);
  const [billingInfoFilledOut, setBillingInfoFilledOut] = useState(false);
  const [amount, setAmount] = useState(20);
  const [billingInformation, setBillingInformation] = useState({
    addressLines: ["123 Main St", "Apartment 1"],
    familyName: "Smith",
    givenName: "John",
    email: "john.smith@gmail.com",
    country: "US",
    phone: "1-800-555-1234",
    state: "TX",
    city: "Dallas",
    postalCode: "75201",
  });
  const [squareInfo, setSquareInfo] = useState({
    applicationId: "",
    locationId: "",
  });

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const accessToken = queryParams.get("accessToken");
  const redirectURL = queryParams.get("redirectURL");

  useEffect(() => {
    // Check if accessToken is missing or empty
    if (!accessToken) {
      setHasAuthError(true);
    }
  }, [accessToken]);

  const handleRedirect = () => {
    // TODO: use this at the end of the process when user clicks the "Done" button
    if (redirectURL) {
      navigate(redirectURL);
    } else {
      // Fallback or default action if redirectURL is not provided
      navigate("/");
    }
  };

  useEffect(() => {
    // TODO: don't send this request if accessToken is missing or empty
    getSquareInfo()
      .then((data) => {
        setSquareInfo(data);
      })
      .catch((error) => {
        alert(error.message || "Failed to get Square info");
      });
  }, []);

  const handleSubmit_Billing = (event) => {
    // TODO: improve form validation
    event.preventDefault();
    const formData = new FormData(event.target);
    const name1 = formData.get("name1");
    const name2 = formData.get("name2");
    const email = formData.get("email");
    const address1 = formData.get("address1");
    const address2 = formData.get("address2");
    const address = address2 ? [address1, address2] : [address1];
    const city = formData.get("city");
    const state = formData.get("state");
    const zip = formData.get("zip");
    const phone = formData.get("phone").replace(/\D/g, "");

    // make sure all fields are filled out
    if (!name1 || !name2 || !address1 || !city || !state || !zip || !phone) {
      alert("Please fill out all fields");
      return;
    }

    if (zip.length !== 5) {
      alert("Please enter a valid zip code");
      return;
    }

    if (phone.length !== 10) {
      alert("Please enter a valid phone number");
      return;
    }

    if (state.length !== 2) {
      alert(
        "Please enter a valid 2 letter state code (ex. enter TX for Texas)"
      );
      return;
    }

    setBillingInformation((prevBillingInformation) => {
      return {
        ...prevBillingInformation,
        addressLines: address,
        familyName: name2,
        givenName: name1,
        email: email,
        phone: phone,
        state: state,
        city: city,
        postalCode: zip,
      };
    });
    setBillingInfoFilledOut(true);
  };

  if (hasAuthError) {
    // Simple error display, can be replaced with a more elaborate error component
    return <div className="error-container">Access denied.</div>;
  }

  return (
    <div className="payment-container">
      <Helmet>
        <title>Bits Market Wallet</title>
      </Helmet>

      {/* Amount */}
      <div>
        <h1>Payment</h1>
        <h2>Enter the amount you would like to pay below</h2>
        <button
          onClick={() => {
            if (amount > 0) {
              setAmount(amount - 5);
            }
          }}
        >
          -$5
        </button>
        <input type="text" name="amount" value={`$${amount}`} disabled />
        <button
          onClick={() => {
            if (amount < 100) {
              setAmount(amount + 5);
            }
          }}
        >
          +$5
        </button>
      </div>

      {/* Billing Info Form */}
      <div>
        <h2>Enter your billing information below</h2>
        <form
          onSubmit={
            // TODO: instead of onSubmit, update info with onChange for any input value
            handleSubmit_Billing
          }
        >
          <input type="text" name="email" placeholder="Email" />
          <input type="text" name="name1" placeholder="First Name" />
          <input type="text" name="name2" placeholder="Last Name" />
          <input type="text" name="address1" placeholder="Address Line 1" />
          <input
            type="text"
            name="address2"
            placeholder="Address Line 2 (Optional)"
          />
          <input type="text" name="city" placeholder="City" />
          <input type="text" name="state" placeholder="State" />
          <input type="text" name="zip" placeholder="Zip Code" />
          <input type="text" name="phone" placeholder="Phone Number" />
          <input type="submit" value="Submit" />
        </form>
      </div>

      {/* Square Payment Form */}
      {billingInfoFilledOut && (
        <SquarePaymentForm
          accessToken={accessToken}
          amount={amount * 100}
          billingInformation={billingInformation}
          applicationId={squareInfo.applicationId}
          locationId={squareInfo.locationId}
          createSquarePayment={createSquarePayment}
          isLive={process.env.REACT_APP_AMPLIFY_ENV === "prod"}
        />
      )}
    </div>
  );
}

export default Payment;
